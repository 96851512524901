import {
  ref, onUnmounted,
} from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';
import moment from 'moment';
import tingeeStoreModule from '../tingeeStoreModule';

export default function useTingeeList() {
  const STORE_MODULE_NAME = 'tingee';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, tingeeStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
      width: '80px',
    },
    {
      label: t('Ngân hàng'),
      field: 'bank.shortName',
    },
    {
      label: t('Số tài khoản gạch nợ'),
      field: 'vaAccountNumber',
    },
    {
      label: t('Số tài khoản'),
      field: 'accountNumber',
    },
    {
      label: t('Chủ tài khoản'),
      field: 'accountName',
    },

  ];
  const rows = ref([]);
  const totalAmount = ref(0);
  // filter
  const selectedRows = ref([]);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   tingee
  const blankItem = {
    id: 0,
    type: 'bank',
    name: '',
    bank: null,
    bankName: '',
    bankAddress: '',
    bankAccountHolder: '',
    bankAccountNumber: '',
    initialAmount: 0,
    initialDate: moment(new Date()).toDate(),
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const deleteItems = ref([]);
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  //   API Call
  const fetchTingees = () => {
    store
      .dispatch('tingee/getTingeeAccounts', serverParams.value)
      .then(response => {
        rows.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteTingees = tingees => {
    store
      .dispatch('tingee/deleteTingees', {
        ids: tingees.map(_obj => _obj.id),
      })
      .then(() => {
        fetchTingees();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const onEditItem = val => {
    item.value = val;
  };

  const onDeleteTingee = val => {
    deleteItems.value = val && val.id > 0 ? [val] : selectedRows.value;
  };

  return {
    item,
    deleteItems,
    columns,
    rows,
    totalAmount,
    selectedRows,
    isLoading,
    totalRecords,
    serverParams,

    resetItem,
    onEditItem,
    fetchTingees,
    deleteTingees,
    onDeleteTingee,
    t,
  };
}
