<template>
  <b-modal
    id="modal-tingee"
    ref="refModal"
    :title="t('Gạch nợ tự động')"
    :ok-title="isSubmitting ? t('Đang kiểm tra') : step === 1 ? isCoporation ? t('Tạo') : t('Lấy mã OTP') : t('Liên kết')"
    :ok-disabled="isSubmitting"
    :cancel-title="t('Huỷ')"
    size="lg"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="bank"
              rules="required"
            >
              <select-bank
                v-model="itemLocal.bank"
                :required="true"
                :api-connect-only="true"
                :default-value="itemLocal.bank"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />

            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="itemLocal.bank">
          <b-col v-if="itemLocal.bank.id !== 3">
            <b-card
              no-body
              :class="`shadow-none border ${isCoporation ? 'bg-light-primary' : 'bg-light-secondary'} `"
              @click="onChangeCoporation(false)"
            >
              <b-card-header>
                <b-card-title>{{ t('TK cá nhân') }}</b-card-title>
                <b-form-radio
                  v-model="isCoporation"
                  name="isCoporation"
                  :value="false"
                  class="custom-control-primary"
                />
              </b-card-header>
            </b-card>

          </b-col>
          <b-col v-if="itemLocal.bank.id !== 3">
            <b-card
              no-body
              :class="`shadow-none border ${!isCoporation ? 'bg-light-danger' : 'bg-light-secondary'} `"
              @click="onChangeCoporation(true)"
            >
              <b-card-header>
                <b-card-title>{{ t('TK doanh nghiệp') }}</b-card-title>
                <b-form-radio
                  v-model="isCoporation"
                  name="isCoporation"
                  :value="true"
                  class="custom-control-danger"
                />
              </b-card-header>
            </b-card>

          </b-col>
        </b-row>
        <div v-if="!isCoporation">

          <b-alert
            :show="!isCoporation"
            variant="info"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertTriangleIcon"
              />
              <span class="ml-25">{{ t('Lưu ý') }}: <strong>{{ t('Thông tin giấy tờ tùy thân') }}</strong> {{ t('và') }} <strong>{{ t('số điện thoại') }}</strong> {{ t('phải TRÙNG KHỚP với thông tin mà bạn đã đăng ký tài khoản thanh toán tại ngân hàng') }}:</span>
            </div>
          </b-alert>
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="accountName"
                rules="required"
              >
                <b-form-group
                  label="Họ và tên (Tiếng Việt không dấu)"
                >
                  <template
                    v-slot:label
                  >
                    {{ t('Họ và tên (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="accountName"
                    v-model="itemLocal.accountName"
                    placeholder="Nguyen Nam Phong"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="accountNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số tài khoản') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="accountNumber"
                    v-model="itemLocal.accountNumber"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="identity"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Giấy tờ tùy thân (CCCD/CMND/Hộ chiếu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="identity"
                    v-model="itemLocal.identity"
                    placeholder="03897546897564"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="mobile"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số điện thoại') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="mobile"
                    v-model="itemLocal.mobile"
                    placeholder="0868987355"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    Email
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="email"
                    v-model="itemLocal.email"
                    placeholder="phongnn@resident.vn"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantName"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Tên merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantName"
                    v-model="itemLocal.merchantName"
                    placeholder="CCMN Resident"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantAddress"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Địa chỉ merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantAddress"
                    v-model="itemLocal.merchantAddress"
                    placeholder="91 Nguyen Chi Thanh"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div v-if="isCoporation">
          <b-row>

            <b-col>
              <validation-provider
                #default="validationContext"
                name="accountNumber"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số tài khoản') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="accountNumber"
                    v-model="itemLocal.accountNumber"
                    placeholder="001788574"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="subId"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Số tài khoản định danh') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="subId"
                    v-model="itemLocal.subId"
                    placeholder="0001"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantName"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Tên merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantName"
                    v-model="itemLocal.merchantName"
                    placeholder="CCMN Resident"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="merchantAddress"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Địa chỉ merchant (Tiếng Việt không dấu)') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="merchantAddress"
                    v-model="itemLocal.merchantAddress"
                    placeholder="91 Nguyen Chi Thanh"
                    trim
                    :state="getValidationState(validationContext)"
                    :disabled="step!==1"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>

        <div
          class="mt-1"
        >

          <b-row v-if="step === 2">
            <b-col
              cols="12"
              class="my-1"
            >
              <div class="d-flex justify-space-between">
                <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                  {{ t('Xác thực mã OTP') }}
                </div>
              </div>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="otpCode"
                rules="required"
              >
                <b-form-group>
                  <template
                    v-slot:label
                  >
                    {{ t('Mã OTP') }}
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="otpCode"
                    v-model="itemLocal.otpCode"
                    placeholder="668866"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <!-- password -->
              <b-form-group
                :label="t('Mật khẩu')"
              >
                <template
                  v-slot:label
                >
                  {{ t('Mật khẩu') }}
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="itemLocal.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

        </div>

        <b-form-group v-if="itemLocal.bank">
          <validation-provider
            #default="{ errors }"
            name="agreement"
            rules="agreement"
          >
            <b-form-checkbox
              id="register-privacy-policy"
              v-model="itemLocal.agreeTerm"
              name="checkbox"
            >
              Tôi đã đọc và đồng ý để {{ itemLocal.bank.shortName }} cung cấp
              thông tin báo "Có" giao dịch trên Tài khoản Định danh của tôi cho
              Resident và đồng ý với
              <b-link :href="itemLocal.bank.consentUrl" target="_blank">
                Điều kiện và điều khoản sử dụng Dịch vụ
              </b-link>
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow, BCol, BAlert,
  BCard, BCardHeader, BCardTitle, BFormRadio,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BLink
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import SelectBank from '@/views/components/SelectBank.vue';
import useTingeeModal from './useTingeeModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadio,
    BInputGroup,
    BInputGroupAppend,
    SelectBank,
    BFormCheckbox,
    BLink
  },
  mixins: [togglePasswordVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const { t } = useI18nUtils();
    const {
      refModal,
      itemLocal,
      isSubmitting,
      isCoporation,
      onChangeCoporation,
      resetItemLocal,
      resetModal,
      step,
      onSubmit,
    } = useTingeeModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      isCoporation,
      onChangeCoporation,
      resetItemLocal,
      resetModal,
      onSubmit,
      step,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      t,
      getValidationState,
    };
  },
};
</script>
