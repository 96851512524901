<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title> {{ t('Gạch nợ tự động') }}</b-card-title>
        <crud-buttons
          :name="t('Gạch nợ tự động')"
          modal="modal-tingee"
          :selected-rows="selectedRows"
          :enable-delete="false"
          :hide-delete="true"
          :enable-add="$can('create', 'tingee')"
          @on-add="resetItem"
        />
      </b-card-header>
      <b-card-body>

        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              style-class="vgt-table striped bordered"
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-tingee-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'name'">
                  <span>{{ props.row.name }}</span>
                  <small
                    v-if="props.row.note"
                    class="text-muted"
                  ><br>{{ props.row.note }}</small>
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'initialAmount'">
                  {{ Number(props.row.initialAmount).toLocaleString() }}
                </span>

                <!-- Column: Amount -->
                <span v-else-if="props.column.field === 'currentAmount'">
                  {{ Number(props.row.currentAmount).toLocaleString() }}
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'type'">
                  <span>{{ props.row.type === 'cash' ? t('Tiền mặt') : props.row.type === 'bank' ? t('Ngân hàng') : t('Ví điện tử') }}</span>
                  <span
                    v-if="props.row.bankAccountHolder"
                    class="text-muted"
                  ><br>{{ t('Chủ TK') }}: {{ props.row.bankAccountHolder }}</span>
                  <span
                    v-if="props.row.bankAccountNumber"
                    class="text-muted"
                  ><br>{{ t('STK') }}: {{ props.row.bankAccountNumber }}</span>
                  <span
                    v-if="props.row.bankName"
                    class="text-muted"
                  ><br>{{ t('Ngân hàng') }}: {{ props.row.bank ? props.row.bank.code : props.row.bankName }}</span>
                  <span
                    v-if="props.row.bankAddress"
                    class="text-muted"
                  ><br>{{ t('Chi nhánh') }}: {{ props.row.bankAddress }}</span>
                </span>

                <span v-else-if="props.column.field === 'default'">
                  <b-badge
                    :variant="props.row.default ? 'light-primary' : 'light-danger'"
                    class="font-small-1"
                  >
                    <span>{{ props.row.default ? t("Có") : t("Không") }}</span>
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    :name="isTingTong ? t('Hình thức thanh toán') : t('Sổ quỹ')"
                    modal="modal-tingee"
                    :disable-delete="props.row.default"
                    :hide-delete="!$can('delete', 'tingee')"
                    :hide-edit="!$can('update', 'tingee')"
                    @on-delete="onDelete(props.row)"
                    @on-edit="onEditItem(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <div slot="emptystate">
                <div class="text-center text-muted">
                  {{ t('Không có bản ghi nào!') }}
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Modal -->
    <tingee-modal
      :item="item"
      @refetch-data="fetchTingees"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BLink,
  BSpinner,
  BBadge,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import TableActions from '@/views/components/TableActions.vue';
import CrudButtons from '@/views/components/CrudButtons.vue';
// eslint-disable-next-line import/no-cycle
import useTingeeList from './useTingeeList';
import TingeeModal from '../modal/TingeeModal.vue';

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BLink,
    BSpinner,
    BBadge,
    VueGoodTable,
    TableActions,
    CrudButtons,
    TingeeModal,
  },

  setup() {
    const {
      item,
      deleteItems,
      columns,
      rows,
      totalAmount,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,

      resetItem,
      onEditItem,
      fetchTingees,
      deleteTingees,
      onDeleteTingee,
      t,
    } = useTingeeList();

    return {
      item,
      deleteItems,
      columns,
      rows,
      totalAmount,
      selectedRows,
      isLoading,
      totalRecords,
      serverParams,

      resetItem,
      onEditItem,
      fetchTingees,
      deleteTingees,
      onDeleteTingee,
      t,
    };
  },

  created() {
    this.fetchTingees();
  },

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

</style>
