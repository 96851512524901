import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getTingeeAccounts(ctx, data) {
      return useJwt.getTingeeAccounts(data).then(response => response);
    },
    connectBankAccount(ctx, data) {
      return useJwt.connectBankAccount(data).then(response => response);
    },
    verifyBankAccount(ctx, data) {
      return useJwt.verifyBankAccount(data).then(response => response);
    },
    tingeeVerifyOCBInfoWithOTP(ctx, data) {
      return useJwt.tingeeVerifyOCBInfoWithOtp(data).then(response => response);
    },
    tingeeOCBCreateVAAccount(ctx, data) {
      return useJwt.tingeeOCBCreateVAAccount(data).then(response => response);
    },
  },
};
